<template>
    <div>
        <v-img :src="require('@/assets/images/logoRed.png')" aspect-ratio="1" class="pa-0 ma-0" alt="INCHARGE" :max-width="$vuetify.breakpoint.xs ? 125 : 250"
            :max-height="$vuetify.breakpoint.xs ? 65 : 130" />

        <div v-if="charger">
            <v-card>
                <v-card-title class="pb-xs-0" v-if="charger && charger.name">{{ chargerName }} ({{ charger.name }})</v-card-title>
                <v-card-title class="pb-xs-0" v-else>{{ chargerName }}</v-card-title>
                <v-card-text v-if="hideDetails == true">
                    <v-switch v-model="forceDetails" inset dense label="Mais informações" class="my-0 py-0" hide-details />
                </v-card-text>
                <v-card-text v-if="hideDetails == false || forceDetails == true">
                    <div class="mt-0 my-sm-2">{{ details }}</div>

                    <div v-if="charger && charger.description">
                        <p class="my-1 my-sm-2" v-html="descriptionFormatted" />
                    </div>

                    <div class="mt-1 my-sm-4 text-subtitle-1">{{ payment }}</div>

                    <v-alert class="mt-1 mb-0" color="secondary white--text" v-if="charger.pricePolicy == 'ACTIVATION_CHARGE' || charger.pricePolicy == 'PAYMENT_PER_CONSUMPTION'">
                        <div class="text-subtitle-1" v-if="charger.pricePolicy == 'ACTIVATION_CHARGE'">
                            <strong>COBRANÇA POR ATIVAÇÃO</strong><br>
                            R${{ this.charger.price }}
                        </div>
                        <div class="text-subtitle-1" v-else>
                            <strong>COBRANÇA POR CONSUMO DE ENERGIA</strong><br>
                            R${{ this.charger.priceKwh }} por kWh<br>
                            Reserva inicial de R${{ this.charger.price }} para ativação.<br>
                            <strong>Estorno final do valor não consumido em até 2 dias úteis.</strong>
                        </div>
                    </v-alert>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>

export default {

    props: {
        charger: {
            required: true,
        },
        chargerName: {
            type: String,
            required: true,
        },
        hideDetails: {
            type: Boolean,
        }
    },

    data() {
        return {
            payment: "",
            details: "",
            forceDetails: false,
        };
    },

    computed: {
        descriptionFormatted() {
            return this.charger.description.replace(/\n/g, "<br>");
        },
    },

    watch: {
        charger: {
            immediate: true,
            handler() {
                this.process();
            },
        },
    },

    methods: {
        process() {
            // if (this.charger.pricePolicy == 'ACTIVATION_CHARGE') {
            //     this.payment = `${this.payment} na ativação pelo valor de R$${this.charger.price}.`;
            // } else if (this.charger.pricePolicy == 'PAYMENT_PER_CONSUMPTION') {
            //     this.payment = `${this.payment}. Pague R$${this.charger.price} na ativação e no final será calculado pelo consumo. Valor de R$${this.charger.priceKwh} por kWh`;
            // }
            if (this.charger) {
                if (this.charger.polity == "PUBLIC") {
                    this.payment = "Público.";
                } else if (this.charger.polity == "PUBLIC_LOCKED") {
                    this.payment = "Público com liberação por aplicativo.";
                } else if (this.charger.polity == "CONDOMINIUM") {
                    this.payment = "Carregador de uso restrito.";
                } else if (this.charger.polity == "CONDOMINIUM_WITH_PAYMENT") {
                    this.payment = "Carregador de uso restrito com liberação por pagamento";
                } else if (this.charger.polity == "PUBLIC_LOCKED_WITH_PAYMENT") {
                    this.payment = "Público com liberação por pagamento";
                } else {
                    this.payment = this.charger.polity;
                }

                const type = this.charger.model == "DC" ? "DC" : "AC";

                if (this.charger.incharge) {
                    this.details = `Carregador (${type}) INCHARGE, modelo ${this.charger.model}`;
                } else {
                    this.details = `Carregador (${type})`;
                }
            } else {
                this.payment = "";
                this.details = "";
            }
        },
    }
};
</script>
