import axios from 'axios'
import store from '@/helpers/store'
import router from '@/helpers/router'

const httpDefault = axios.create({
    // baseURL: (process.env.NODE_ENV === 'production') ? 'https://incharge.app:3000/' : 'http://10.8.0.6:3000/',
    baseURL: (process.env.NODE_ENV === 'production') ? 'https://incharge.app:3000/' : 'https://incharge.app:3000/',
});

const httpNewBackend = axios.create({
    baseURL: (process.env.NODE_ENV === 'production') ? 'https://incharge.app:3001/' : 'http://10.8.0.6:3001/',
    // baseURL: (process.env.NODE_ENV === 'production') ? 'https://incharge.app:3001/' : 'https://incharge.app:3001/',
});

httpDefault.interceptors.request.use(req => {
    if (store.getters.isLogged) {
        req.headers.Authorization = `Bearer ${store.state.token}`;
    }
    return req;
}, error => {
    return Promise.reject(error);
});

httpNewBackend.interceptors.request.use(req => {
    if (store.getters.isLogged) {
        req.headers.Authorization = `Bearer ${store.state.token}`;
    }
    return req;
}, error => {
    return Promise.reject(error);
});

httpNewBackend.interceptors.response.use(response => {
    store.commit("ONLINE");
    return response.data;
}, async function (error) {
    return error.response ? Promise.reject(error.response.data) : Promise.reject({ "message": "Sistema desconectado." });
});

httpDefault.interceptors.response.use(response => {
    store.commit("ONLINE");
    return response.data;
}, async function (error) {
    const originalRequest = error.config;

    const status = error.response ? error.response.status : null;
    if (status === 401) {
        // if (router.currentRoute.path != "/login") {
        if (error.response.config.url != "api/v2/auth" && store.state.login.remember) {
            let req = { email: store.state.login.email, password: store.state.login.password, notification: store.state.notification };
            return httpDefault.post("api/v2/auth", req).then(
                response => {
                    store.commit("SET_LOGIN", { response, email: store.state.login.email, password: store.state.login.password, remember: true });
                    return httpDefault.request(originalRequest);
                }
            ).catch(() => {
                store.commit("CLEAR_LOGIN");
                if (router.currentRoute.path != "/") {
                    router.replace({ path: "/" });
                }
            });
        } else {
            store.commit("CLEAR_LOGIN");
            // if (router.currentRoute.path != "/") {
            //     router.replace({ path: "/" });
            // }
        }
        // }
    } else if (status === 403) {
        if (router.currentRoute.path != "/") {
            router.replace({ path: "/" });
        }
    }

    if (error.response) {
        store.commit("ONLINE");
    } else {
        store.commit("OFFLINE");
    }

    return error.response ? Promise.reject(error.response.data) : Promise.reject({ "message": "Sistema desconectado." });
});

export const httpAlternative = httpNewBackend
export const http = httpDefault

export default httpDefault