<template>
    <div class="text-center pb-0">
        <v-btn v-if="showUnlockCharger" @click="sendUnlockAPP" :disabled="block" style="width: 90%" color="success" class="elevation-12">
            <v-icon left> play_arrow </v-icon> Ativar
        </v-btn>
        <v-btn v-if="showRecognize" @click="sendRecognize" :disabled="block" style="width: 90%" color="success" class="elevation-12">
            <v-icon left> sync </v-icon> Reconhecer o carregamento
        </v-btn>

        <v-btn v-if="showStopCharger" @click="stopCharger" :disabled="block" style="width: 90%" color="success" class="elevation-12">
            <v-icon left> cancel </v-icon> Encerrar
        </v-btn>

        <v-btn v-else-if="showStopChargerByCode" @click="showModalStopChargerByCode = true" :disabled="block" style="width: 90%" color="success" class="elevation-12">
            <v-icon left> cancel </v-icon> Encerrar
        </v-btn>

        <ConfirmModal confirmText="Encerrar" cancelText="Cancelar" ref="confirm" />

        <StopChargerByCode :show="showModalStopChargerByCode" :chargerName="chargerName" :plug="plug" @close="showModalStopChargerByCode = false" />

        <PaymentOptions :chargerName="chargerName" :price="charger.price" :plug="plug.plug" :backgroundColor="color" v-if="showPay" />
    </div>
</template>

<script>
import ConfirmModal from "@/components/core/ConfirmModal";
import statusCharger from "@/helpers/StatusCharger";
import PaymentOptions from "@/components/shared/Payment/PaymentOptions";
import StopChargerByCode from "./StopChargerByCode";

export default {
    props: {
        chargerName: {
            required: true,
        },
        charger: {
            required: true,
        },
        plug: {
            required: true,
        },
        gotoDetails: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
        },
        secondColor: {
            type: String,
        },
    },

    components: { ConfirmModal, PaymentOptions, StopChargerByCode },

    data() {
        return {
            statusCharger,
            block: false,
            firstUpdate: true,

            showPay: false,
            showUnlock: false,
            showRecognize: false,
            showModalStopChargerByCode: false,
        };
    },

    mounted() {
        this.update();
    },

    watch: {
        plug: {
            immediate: true,
            handler() {
                this.update();
            },
        },
    },

    computed: {
        showUnlockCharger() {
            return this.showUnlock && this.statusCharger.inStartStatus(this.plug.status) == true;
        },
        showStopCharger() {
            return this.canStop && this.plug.permissionToPlug == "USER_OK" && this.statusCharger.inStartStatus(this.plug.status) == false;
        },
        showStopChargerByCode() {
            return this.canStop && this.plug.unlockBy == "PAY" && this.statusCharger.inStartStatus(this.plug.status) == false;
        },
        canStop() {
            return this.charger != null && (this.charger.protocol == "1.6" || this.charger.protocol == "2.0.1" || this.charger.protocol == "API") && this.plug && this.plug.stoppedForced != true && this.statusCharger.isOccupied(this.plug.status)
        },
        showRestart() {
            return this.plug && this.plug.permissionToPlug == "USER_OK" && this.statusCharger.isOccupied(this.plug.status) && this.plug.stoppedForced;
        },
    },

    methods: {
        unblockDelay(time = 60000) {
            this.block = true;
            setTimeout(() => {
                this.block = false;
            }, time);
        },
        update() {
            this.showRecognize = false;
            this.showUnlock = false;
            this.showPay = false;

            if (this.charger.price > 0) {
                if (this.plug.status == "Charging" && this.plug.unlockBy == "FREE_POWER") {
                    this.showPay = true;
                } else if (this.plug.status == "Preparing") {
                    if (this.plug.unlockBy == "PAY") {
                        this.showUnlock = true;
                    } else if (this.charger.polity == 'CONDOMINIUM_WITH_PAYMENT') {
                        if (this.charger.permissionToCharger != 'OK') { // Show pay method only if user is not able to use by condominium
                            this.showPay = true;
                        } else {
                            this.showUnlock = true;
                        }
                    } else if (this.charger.polity == 'PUBLIC_LOCKED_WITH_PAYMENT') {
                        if (this.plug.unlockBy != "APP") {
                            this.showPay = true;
                        }
                    }
                } else if (this.plug.status == 'Available') {
                    if (this.charger.polity == 'CONDOMINIUM_WITH_PAYMENT') {
                        if (this.charger.permissionToCharger != 'OK') { // Show pay method only if user is not able to use by condominium
                            this.showPay = true;
                        }
                    } else {
                        this.showPay = true;
                    }
                }
            } else {
                const userCanOperate = this.charger.permissionToCharger == 'OK' && this.plug.permissionToPlug == 'NO_USER_LINKED';
                if (this.plug.status == "Charging" && this.plug.unlockBy != "FREE_POWER" && userCanOperate) {
                    this.showRecognize = true;
                } else if (((this.charger.polity == 'PUBLIC' && this.statusCharger.hasConnectedCarButNotCharging(this.plug.status)) || this.plug.status == 'Charging') && userCanOperate) {
                    this.showRecognize = true;
                } else if (this.statusCharger.hasConnectedCarButNotCharging(this.plug.status) && userCanOperate) {
                    if (this.plug.unlockBy == "NFC") {
                        this.showRecognize = true;
                    } else {
                        this.showUnlock = true;
                    }
                }
            }

            if (this.firstUpdate == true) {
                this.firstUpdate = false;
                if (this.plug.unlockBy != null && (this.showUnlock == true || this.showPay == true)) {
                    this.unblockDelay();
                }
            }
        },
        sendRecognize() {
            this.block = true;
            this.$http
                .post("api/v2/recognize", { charger: this.chargerName, plug: this.plug.plug })
                .then((result) => {
                    this.unblockDelay();
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Enviado com sucesso.");
                })
                .catch((error) => {
                    this.block = false;
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao enviar, tente novamente.");
                });
        },
        sendUnlockAPP() {
            this.block = true;
            this.$http
                .postAlternativeAPI("api/v2/charger/unlock", { charger: this.chargerName, plug: this.plug.plug })
                .then((result) => {
                    this.unblockDelay();
                    if (this.unlockAPP) {
                        this.unlockAPP = false;
                    }
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Enviado com sucesso.");
                })
                .catch((error) => {
                    this.block = false;
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao enviar, tente novamente.");
                });
        },
        stopCharger() {
            this.$refs.confirm.open("", "Deseja mesmo encerrar o carregamento?").then((confirm) => {
                if (confirm) {
                    this.block = true;
                    this.$http
                        .postAlternativeAPI("api/v2/charger/stop", { charger: this.chargerName, plug: this.plug.plug })
                        .then((result) => {
                            this.unblockDelay();
                            this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Enviado com sucesso.");
                        })
                        .catch((error) => {
                            this.block = false;
                            this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao enviar, tente novamente.");
                        });
                }
            });
        },
    },
};
</script>
