<template>
    <div>
        <div v-if="show">
            <v-btn color="primary" fixed dark outlined :small="$vuetify.breakpoint.xs" :text="false" right top style="background-color: white; top: 5px; right: 105px"
                class="elevation-24" @click="register">
                Cadastre-se
            </v-btn>
            <v-btn color="success" dark fixed right :small="$vuetify.breakpoint.xs" top style="top: 5px; right: 5px" class="elevation-24" @click="open">
                Entrar
            </v-btn>
        </div>


        <router-link to="/" tag="v-btn" v-else>
            <v-btn color="primary" fab dark fixed right top class="elevation-24 d-print-none" style="top: 2px; right: 2px; z-index:1000;">
                <v-icon color=""> home </v-icon>
            </v-btn>
        </router-link>

    </div>
</template>

<script>
export default {

    data() {
        return {
            show: false,
        };
    },
    methods: {
        open() {
            this.$eventHub.$emit("openLogin");
        },
        register() {
            this.$eventHub.$emit("openRegister");
        }
    },
    watch: {
        '$route': {
            handler: function (to) {
                this.show = to.path == "/";
            },
            deep: true,
            immediate: true
        }
    },
};
</script>