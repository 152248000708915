<template>
    <v-row>
        <v-btn color="primary" dark outlined :small="$vuetify.breakpoint.xs" :text="false" style="background-color: white" class="elevation-24 mr-2" @click="register">
            Cadastre-se
        </v-btn>
        <v-btn color="success" dark :small="$vuetify.breakpoint.xs" class="elevation-24" @click="open">
            Entrar
        </v-btn>
    </v-row>
</template>

<script>
export default {
    methods: {
        open() {
            this.$eventHub.$emit("openLogin");
        },
        register() {
            this.$eventHub.$emit("openRegister");
        }
    }
};
</script>